/* eslint-disable */
<template>
  <div class="home admin-home" style="height: 100vh">
    <SpecialistNavigation ref="navigation" indexProps="0" />
    <div class="top-panel" style="width: 96%; height: auto">
      <Header />
      <div class="header-title-section" style="width: auto; height: auto; flex-grow: 1; padding: 20px">
        <div class="header-title"><h1>Welcome to Red Health Online</h1></div>
        <div class="header-sub-title">Your dashboard for all booking related activities.</div>
      </div>
      <div v-if="specialist.Title" style="width: auto; height: auto" class="d-flex flex-column align-items-center header-title-section">
        <h3>{{specialist.fullName}}</h3>
        <div v-if="profileThumbnail" class="rounded-image smaller-rounded-image m-t-0 m-t-b">
          <div class="profile-image-content"><img :src="profileThumbnail"/></div>
        </div>
        <div v-else>
          <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
            <div class="profile-image-content notice-background"><s class="text-initials small">{{getInitials(specialist)}}</s></div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel" style="width: 96%; top: 150px">
      <div class="d-flex justify-content-center flex-column align-items-center">
        <div class="tool-bar cards" style="width: 95%; border-radius: 10px">
          <div class="cards-item " style="width: 15%; height: auto">
            <router-link class="d-flex flex-column justify-content-between h-100" :to="{ name: 'specialist-progress-bookings', params: {} }">
              <div class="cards-item-number">{{ adminDashboard.AwaitingDictationCount || 0 }}</div>
              <div>
                <div>Awaiting Dictation</div>
              </div>
            </router-link>
          </div>
          <div class="cards-item" style="width: 15%; height: auto">
            <router-link class="d-flex flex-column justify-content-between h-100" :to="{ name: 'specialist-progress-bookings', params: {} }">
              <div class="cards-item-number">{{ adminDashboard.ReportToReviewCount || 0 }}</div>
              <div>
                <div>Reports to Review</div>
              </div>
            </router-link>
          </div>
          <div class="cards-item" style="width: 15%; height: auto">
            <router-link class="d-flex flex-column justify-content-between h-100" :to="{ name: 'specialist-appointments', params: {} }">
              <div class="cards-item-number">{{ adminDashboard.UpcommingBookingsCount || 0 }}</div>
              <div>
                <div>Upcoming Bookings</div>
              </div>
            </router-link>
          </div>
          <div class="cards-item" style="width: 15%; height: auto">
            <router-link class="d-flex flex-column justify-content-between h-100" :to="{ name: 'specialist-court-cases', params: {} }">
              <div class="cards-item-number">{{ adminDashboard.CourtCasesAndTeleconferenceCount || 0 }}</div>
              <div>
                <div>Court Cases and Teleconferences</div>
              </div>
            </router-link>
          </div>
        </div>
        <br />
        <div class="content-scroll-x p-2 font-weight-bold" style="background: #FDE9EA; width: 96%; border-radius: 10px">
          <router-link :to="{ name: 'specialist-progress-bookings', query: { overdue: true } }">
            Overdue Reports
          </router-link>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Header from '@/components/Header'
import SpecialistNavigation from "@/components/SpecialistNavigation";

export default {
  name: 'AdminHome',
  components: {
    SpecialistNavigation,
    Header,
  },
  data() {
    return {
      profileThumbnail: null,
      requireTravelNumber: 0,
      requireActionNumber: 0,
      bookingClashNumber: 0,
      bookingCancelNumber: 0,
      searchLogs: [],
      adminDashboard: {},
      specialist: {},
      updatedKey: 0,
    }
  },
  methods: {
    getInitials(specialist) {
      var initials = ""

      if (specialist.FirstName) {
        initials = specialist.FirstName.substring(0,1)
      }

      if (specialist.Surname) {
        initials = initials + specialist.Surname.substring(0,1)
      }
      return initials
    },
    formatDate(dateToFormat, newFormat, currentFormat) {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    addComma(text) {
      return utilities.addComma(text)
    },
    viewAll() {
      this.$router.push({ name: 'admin-view-search-log', params: {} })
    },

    async loadData() {
      var dashboardData = await utilities.getSpecialistDashboardData()
      this.adminDashboard = dashboardData
      this.specialist = JSON.parse(this.adminDashboard.Specialist)
      this.specialist.firstName = utilities.convertNullToEmpty(this.specialist.FirstName)
      this.specialist.lastName = utilities.convertNullToEmpty(this.specialist.Surname)
      this.specialist.title = utilities.convertNullToEmpty(this.specialist.Title)
      this.specialist.fullName = utilities.getSpecialistFullName(this.specialist)
      this.profileThumbnail = await utilities.getProfileThumbnail(this.specialist.Photo)
      this.updatedKey += 1
    },

  },
  async beforeMount() {
    $('.zsiq_floatmain').hide()
    await this.loadData()
  },
  mounted() {

  },
}
</script>
