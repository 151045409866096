<template>
  <div class="send-email-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>{{ "Contact " + (redHealthMayContactClaimant &&
              ((emailOrSms == 0 && emailProps.claimant != '') ||
              (emailOrSms == 1 && phoneProps.claimant != '')) ? "Claimant /" : "") + " Client / Specialist" }}</h1>
            </div>
            <button
              type="button"
              class="close-icon"
              @click="handleModal($event)"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="row d-flex align-items-center">
              <div class="col-4">
                <v-btn-toggle v-model="emailOrSms" mandatory @change="changeOption()">
                  <v-btn style="height: 30px" class="radio-toggle"> Email </v-btn>
                  <v-btn style="height: 30px" class="radio-toggle"> SMS </v-btn>
                </v-btn-toggle>
              </div>
              <div class="col-8 d-flex align-items-center">
                <el-select v-if="
                    (emailOrSms === 0 && emailProps.client !== '' ) ||
                    (emailOrSms === 1 && phoneProps.client !== '')" v-model="sendTo" :popper-append-to-body="false" @change="radioChange">
                  <el-option value="client" label="Client">Client</el-option>
                  <el-option value="claimant" label="Claimant">Claimant</el-option>
                  <el-option value="specialist" label="Specialist">Specialist</el-option>
                  <el-option value="rhadmin" label="RHAdmin">RHAdmin</el-option>
                  <el-option value="custom" label="Custom">Custom</el-option>
                </el-select>
                <el-select class="ml-2" filterable v-model="curTemplate" placeholder="Select Template" @change="onChangeSelect" :popper-append-to-body="false">
                  <el-option v-for="item in filteredRecords" :key="item.EmailTemplateID" :value="item.EmailTemplateID" :label="decodeURIComponent(item.TemplateName)">{{decodeURIComponent(item.TemplateName)}}</el-option>
                </el-select>
                <v-btn
                    dark
                    class="ml-2 btn-light"
                    v-show="sendTo === 'client' && emailOrSms === 0 && !hideButtons"
                    @click="resendBookingConfirmation">
                  Resend Booking Confirmation
                </v-btn>
              </div>
            </div>
            <form>
              <div>
                <div>
                  <div>
                    <table class="table">
                      <thead>
                        <tr style="height: 30px">
                          <th style="padding: 0 0 0 30px" class="icon-col">
                            <input
                              type="checkbox" size="medium"
                              class="form-checkbox-all"
                              style="margin-left: 10px"
                              @change="changeCheckboxAll($event.target.checked)"
                            />
                          </th>
                          <th style="width: 90%; padding: 0 15px" class="long-text">
                            Form Name
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    :class="standardFiles.length > 5 ? 'content-scroll' : ''"
                  >
                    <table class="table">
                      <tbody>
                        <tr v-for="(file, index) in standardFiles" :key="index">
                          <td style="width: 10%" class="icon-col pt-0 pb-0">
                            <input
                              type="checkbox"
                              class="form-checkbox ml-5"
                              :value="file.name"
                              @change="changeCheckbox($event, file)"
                            />
                          </td>
                          <td
                            style="width: 75%"
                            class="long-text pt-0 pb-0"
                            data-placement="right"
                            data-toggle="tooltip"
                            :title="file.name"
                          >
                            <a :href="getUrl(file)" target="blank">{{
                              file.name
                            }}</a>
                          </td>
                          <td style="width: 15%" class="long-text pt-0 pb-0">
                            <el-button v-show="file.type === 'link'" @click="copyText(getUrl(file))" size="small">Copy Url</el-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="emailOrSms === 0" class="row">
                <div class="col-4">
                  <el-input
                    type="text"
                    v-model="email.Subject"
                    placeholder="Subject"
                    v-if="emailOrSms === 0"
                  />
                  <div v-if="hasError && !validateSubject" class="text-danger">
                    Email subject is required.
                  </div>
                </div>
                <div class="col-4" v-if="sendTo !== 'rhadmin'">
                  <el-input
                      type="text"
                      v-model="email.EmailTo"
                      placeholder="Email To"
                      v-show="emailOrSms === 0 && (sendTo === 'specialist' || sendTo === 'claimant')"
                  />
                  <el-select class="w-100"
                             v-model="email.EmailTo"
                             placeholder="Email To"
                             filterable
                             clearable
                             name="email"
                             autocomplete="email"
                             :popper-append-to-body="false"
                             @change="onSelectEmail"
                             default-first-option
                             allow-create v-show="emailOrSms === 0 && (sendTo === 'client' || sendTo === 'custom')">
                    <el-option v-for="(item, i) in listContacts" :key="i" :value="item" :label="item"></el-option>
                  </el-select>
                  <div v-if="hasError && !validateEmailTo" class="text-danger">
                    Email is required.
                  </div>
                </div>
                <div class="col-4">
                  <el-select multiple class="w-100" v-show="emailOrSms === 0"
                             placeholder="Email Cc"
                             v-model="email.EmailCc"
                             filterable
                             clearable
                             name="emailcc"
                             autocomplete="emailcc"
                             :popper-append-to-body="false"
                             @change="onSelectEmail"
                             default-first-option
                             allow-create>
                    <el-option v-for="(item, i) in listContacts" :key="i" :value="item" :label="item"></el-option>
                  </el-select>
                </div>
                <div class="col-4">
                  <el-select multiple class="w-100" v-show="emailOrSms === 0"
                             placeholder="Email Bcc"
                             v-model="email.EmailBcc"
                             filterable
                             clearable
                             name="emailbcc"
                             autocomplete="emailbcc"
                             default-first-option
                             :popper-append-to-body="false"
                             @change="onSelectEmail"
                             allow-create>
                    <el-option v-for="(item, i) in listContacts" :key="i" :value="item" :label="item"></el-option>
                  </el-select>
                </div>
              </div>
              <div v-if="emailOrSms === 1" class="row">
                <div class="col-4">
                  <el-input
                      type="text"
                      v-model="phone"
                      placeholder="Phone Number"
                  />
                  <div v-if="hasError && !validatePhone" class="text-danger">
                    Please enter a valid mobile number in International format without spaces - i.e. +61400123456
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <Editor apiKey="5wu9mqdus3m0ugez0lnjdl6dcxezik3j0rnecxh62g4xf31p" :init="option" ref="editor" v-if="emailOrSms === 0"
                    v-model="email.Content"
                    placeholder="Message"/>
                  <textarea v-else
                    v-model="email.smsBody"
                    placeholder="Message"
                    style="overflow: hidden; resize: vertical"
                    :class="hasError && !validateMessage ? 'is-invalid' : ''"></textarea>
                  <div v-if="hasError && !validateMessage" class="text-danger">
                    Message body is required.
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn
                      class="white-button"
                      @click="handleModal($event)"
                      dark>Cancel</v-btn>
                    <v-btn dark @click="sendEmail">Send</v-btn>
                  </div>
                </div>
              </div>

              <div class="row">
                <v-btn
                  dark
                  class="mr-2 btn-light"
                  v-if="sendTo == 'claimant' && emailOrSms == 0 && !hideButtons"
                  @click="resendConfirmationAttendance">
                  Resend Confirmation of Attendance
                </v-btn>
                <v-btn
                  dark
                  class="mr-2 btn-light"
                  v-if="sendTo == 'claimant' && emailOrSms == 0 && !hideButtons"
                  @click="resendConsentEmail">
                  Resend Consent Email
                </v-btn>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/skins/ui/oxide/skin.css";

export default {
  name: "SendEmailModal",
  components: {
    Editor
  },
  props: [
    "client",
    "sendToProps",
    "emailProps",
    "nameProps",
    "emailSmsProps",
    "phoneProps",
    "bookingFiles",
    "bookingProps",
    "clientContact",
    "specialist",
    "locationName"
  ],
  data() {
    return {
      option: {
        plugins: 'link image code table',
        height: 400,
        branding: false,
        menu: false
      },
      records: [],
      filteredRecords: [],
      editContent: {},
      curTemplate: null,
      loadModal: false,
      redHealthMayContactClaimant: true,
      email: utilities.initEmail(),
      phone: '',
      commonFiles: [],
      loaded: false,
      byName: false,
      hasError: false,
      hideButtons: false,
      sendTo: "",
      listContacts: [],
      ccList: [],
      standardFiles: [],
      emailOrSms: 0,
      files: [],
    };
  },
  computed: {
    validateEmail() {
      return this.emailOrSms === 1 || (this.email && this.email.Content && this.email.Content !== "");
    },
    validateSubject() {
      return this.emailOrSms === 1 || (this.email && this.email.Subject && this.email.Subject !== "");
    },
    validateEmailTo() {
      return this.emailOrSms === 1 || this.sendTo === 'rhadmin' || (this.email && this.email.EmailTo && this.email.EmailTo !== "");
    },
    validateMessage() {
      return this.emailOrSms === 0 || (this.email && this.email.smsBody && this.email.smsBody!== "");
    },
    validatePhone() {
      return this.emailOrSms === 0 || this.sendTo === 'rhadmin' || (this.phone && this.phone.trim() !== '' && utilities.validatePhoneAU(this.phone));
    }
  },
  watch: {
    clientContact: {
      handler(value) {
        if (value && value.length > 0) {
          value.forEach(item => {
            if(item.NotificationEmailAddress !== '') {
              this.listContacts.push(item.NotificationEmailAddress)
              this.ccList.push(item.NotificationEmailAddress)
            }
            if(item.emailAddress !== '') {
              this.listContacts.push(item.emailAddress)
              this.ccList.push(item.emailAddress)
            }
          })
          this.ccList.push(this.specialist.NotificationEmailAddress !== '' ? this.specialist.NotificationEmailAddress : this.specialist.emailAddress)
          this.ccList.push(this.bookingProps.claimantEmail)
          let obj = value.find(item => item.primaryContact)
          if(obj) {
            this.email.EmailTo = obj.NotificationEmailAddress !== '' ? obj.NotificationEmailAddress : obj.emailAddress
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    copyText(textToCopy) {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },
    onSelectEmail() {
      this.$forceUpdate()
    },
    getEmailTo() {
      if(this.sendTo === 'client') {
        this.email.EmailTo = this.bookingProps.clientContacts[0].NotificationEmailAddress !== '' ? this.bookingProps.clientContacts[0].NotificationEmailAddress : this.bookingProps.clientContacts[0].email
      } else if (this.sendTo === 'specialist') {
        this.email.EmailTo = this.specialist.NotificationEmailAddress !== '' ? this.specialist.NotificationEmailAddress : this.specialist.email
      } else if (this.sendTo === 'claimant') {
        this.email.EmailTo = this.bookingProps.claimantEmail
      } else {
        this.email.clientEmail = null
        this.email.EmailTo = null
      }
      this.$forceUpdate()
    },
    radioChange(v) {
      this.curTemplate = null
      if(v === 'client') {
        this.filteredRecords = this.records.filter(item => item.EmailTo === 1)
      } else if (v === 'specialist') {
        this.filteredRecords = this.records.filter(item => item.EmailTo === 2)
      }  else if (v === 'claimant') {
        this.filteredRecords = this.records.filter(item => item.EmailTo === 3)
      } else {
        this.filteredRecords = this.records
      }
      this.getEmailTo()
      if(this.emailOrSms === 1) {
        this.phone = this.phoneProps[this.sendTo]
      }
    },
    onChangeSelect(v) {
      if(!v) {
        this.email = utilities.initEmail()
      } else {
        this.email = _.cloneDeep(this.records.find(item => item.EmailTemplateID === v))
        this.email.Subject = this.getEmailAutoFill(this.email.Subject)
        this.email.Content = this.getEmailAutoFill(this.records.find(item => item.EmailTemplateID === v).Body)
        this.getEmailTo()
      }
    },
    getEmailAutoFill(data) {
      data = data.replaceAll('${ClientName}', this.bookingProps.clientName)
      data = data.replaceAll('${ClientContactName}', this.bookingProps.clientContacts ? this.bookingProps.clientContacts[0].clientContactFullName : '')
      data = data.replaceAll('${BookingId}', this.bookingProps.id)
      data = data.replaceAll('${BookingType}', this.bookingProps.bookingTypeName)
      data = data.replaceAll('${BookingStatus}', this.bookingProps.bookingStatusName)
      data = data.replaceAll('${Specialty}', this.bookingProps.qualification.join(','))
      data = data.replaceAll('${BookingTime}', window.moment(this.bookingProps.bookingDateTime).format('DD/MM/YYYY hh:mm A'))
      data = data.replaceAll('${ClaimantName}', this.bookingProps.claimantFullName)
      data = data.replaceAll('${ClaimantBookingDateTime}', window.moment(this.bookingProps.ClaimantBookingDateTime).format('DD/MM/YYYY hh:mm A'))
      data = data.replaceAll('${Specialist}', this.bookingProps.sFullName)
      data = data.replaceAll('${Location}', this.locationName)
      data = data.replaceAll('${CaseReferenceNumber}', this.bookingProps.caseReference)
      data = data.replaceAll('${ClaimantLocation}', this.bookingProps.ClaimantLocation)
      data = data.replaceAll('${VideoLinkURL}', this.bookingProps.videoLinkUrl)
      data = data.replaceAll('${PaperworkDue}', window.moment(this.bookingProps.paperworkDue).format('DD/MM/YYYY'))
      data = data.replaceAll('${DateOfAccident}', this.bookingProps.accidentDate)
      data = data.replaceAll('${AdditionalTime}', this.formatHoursString(this.bookingProps.bookingHours.filter(item => item.status === '0')))
      data = data.replaceAll('${Pre-approvedHours}', this.formatHoursString(this.bookingProps.bookingHours.filter(item => item.status === '3')))
      data = data.replaceAll('${ExamineeLocationForVideoLink}', this.bookingProps.ClaimantLocation)
      data = data.replaceAll('${ClaimantStateForVideoLink}', this.bookingProps.claimantStateDuringBooking)
      data = data.replaceAll('${ClaimantDateOfBirth}', window.moment(this.bookingProps.dateOfBirth).format('DD/MM/YYYY'))
      data = data.replaceAll('${ClientContactEmailAddress}', this.bookingProps.clientContacts ? (this.bookingProps.clientContacts[0].NotificationEmailAddress || this.bookingProps.clientContacts[0].clientContactEmailAddress) : '')
      data = data.replaceAll('${ClaimantAddress}', this.getAddress())
      data = data.replaceAll('${ClientAddress}', this.getClientAddress())
      // data = data.replaceAll("%27", "'")
      return data
    },

    getAddress(){
      let str = ''
      if(this.bookingProps.claimantAddressLine1) {
        str += this.bookingProps.claimantAddressLine1 + '<br>'
      }
      if(this.bookingProps.claimantAddressLine2) {
        str += this.bookingProps.claimantAddressLine2 + '<br>'
      }
      if(this.bookingProps.claimantCity) {
        str += this.bookingProps.claimantCity + ', '
      }
      if(this.bookingProps.claimantState) {
        str += this.bookingProps.claimantState + ', '
      }
      if(this.bookingProps.claimantPostcode) {
        str += this.bookingProps.claimantPostcode
      }
      return str
    },

    getClientAddress(){

      let str = ''
      if(this.client.addressLine1) {
        str += this.client.addressLine1 + '<br>'
      }
      if(this.client.addressLine2) {
        str += this.client.addressLine2 + '<br>'
      }
      if(this.client.city) {
        str += this.client.city + ', '
      }
      if(this.client.state) {
        str += this.client.state + ', '
      }
      if(this.client.postcode) {
        str += this.client.postcode
      }
      return str
    },

    formatHoursString(arr) {
      let str = ''
      arr.forEach(item => {
        str+= `${item['service']}: ${item.numberOfHours} hours <br>`
      })
      return str
    },

    handleModal(e) {
      this.loadModal = !this.loadModal;
      if (this.loadModal) {
        $("html").addClass("modal-mask-open");
      } else {
        $("html").removeClass("modal-mask-open");
      }
    },

    changeOption() {
      if (this.emailOrSms == 0 && this.emailProps.claimant == '') {
         this.sendTo = 'client'
      } else if (this.emailOrSms === 1) {
        this.sendTo = 'custom'
      }
    },

    sortName() {
      this.commonFiles.sort((a, b) => {
        if (this.byName) {
          // ASC
          return a.fileName > b.fileName ? 1 : -1;
        } else {
          // DESC
          return a.fileName < b.fileName ? 1 : -1;
        }
      });
      this.byName = !this.byName;
    },
    changeCheckboxAll(e) {
      this.files = []
      if (e) {
        this.files = this.standardFiles
        $(".form-checkbox").prop("checked", true);
      } else {
        this.files = []
        $(".form-checkbox").prop("checked", false);
      }
    },
    changeCheckbox(e, file) {
      if (e.target.checked) {
        this.files.push(file);
      } else {
        this.files = this.files.filter((x) => x.name !== e.target.value);
      }
      var checkedNumber = $(".form-checkbox:checked").length;
      if (checkedNumber == $(".form-checkbox").length) {
        $(".form-checkbox-all").prop("checked", true);
      } else {
        $(".form-checkbox-all").prop("checked", false);
      }
    },

    resendBookingConfirmation() {
      this.callsendBookingEmail("BookingConfirmation")
    },

    resendConfirmationAttendance() {
      this.callsendBookingEmail("AttendanceConfirmation")
    },

    resendConsentEmail() {
      this.callsendBookingEmail("ConsentConfirmation")
    },

    async callsendBookingEmail(emailType) {
      var fullName = ""
      var email = ""
      if (this.sendTo == 'claimant') {
        fullName = this.nameProps.claimant
        email = this.emailProps.claimant
      } else {
        fullName = this.nameProps.client
        email = this.emailProps.client
      }

      if ((emailType == "ConsentConfirmation" || emailType == "AttendanceConfirmation") && email == "") {
        fullName = this.nameProps.client
        email = this.emailProps.client
      }

      var params = {
        EmailTo: email,
        EmailType: emailType,
        EmailName: fullName,
        BookingID: this.$parent.booking.id,
        UserID: this.$parent.user.id,
        UserName: this.$parent.user.name,
        BookingDateTime: moment(this.$parent.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss'),
        ClaimantBookingDateTime: moment(this.$parent.booking.claimantBookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss'),
        DueDate: this.$parent.booking.paperworkDue,
        LocationName: this.$parent.getLocationName(this.$parent.booking.locationId),
        SpecialistTitle: this.$parent.specialist.title,
        SpecialistFirstName: this.$parent.specialist.firstName,
        SpecialistSurname: this.$parent.specialist.lastName,
        ClaimantFirstName: this.$parent.booking.claimantFirstName,
        ClaimantSurname: this.$parent.booking.claimantSurname,
        BookingStatus: this.$parent.booking.bookingStatus,
        BookingTypeName: this.$parent.booking.bookingTypeName,
        BookingTypeID: this.$parent.booking.bookingTypeId,
      }
      var isClaimantEmail = false
      var isClaimantMobile = false
      if (emailType == "BookingConfirmation" || emailType == "AttendanceConfirmation" || emailType == "ConsentConfirmation") {
        params.ClaimantStateDuringBooking = this.$parent.booking.claimantStateDuringBooking
        params.LocationState = this.$parent.getLocationState(this.$parent.booking.locationId)
        if (this.emailProps.claimant != "") {
          isClaimantEmail = true
          params.ClaimantEmail = this.emailProps.claimant
        }

        if (this.phoneProps.claimant != "") {
          isClaimantMobile = true
          params.ClaimantMobile = this.phoneProps.claimant
        }
      }
      params.IsClaimantEmail = isClaimantEmail
      params.IsClaimantMobile = isClaimantMobile

      var res = await utilities.postResendBookingEmail(params);
      if (res) {
        if(emailType === 'BookingConfirmation') {
          this.$parent.successMessage =
              "Confirmation Email re-sent to " + (this.sendToProps === 'client' ? 'Client Contact' : this.sendToProps) + ".";
          utilities.showAlert(".alert-success");
          this.handleModal();
        } else if (emailType === 'AttendanceConfirmation') {
          this.$parent.successMessage = "Confirmation of Attendance re-sent to Claimant"
          utilities.showAlert(".alert-success");
          this.handleModal();
        } else if (emailType === 'ConsentConfirmation') {
          this.$parent.successMessage = "Request for Consent Form re-sent to Claimant"
          utilities.showAlert(".alert-success");
          this.handleModal();
        }
      }
    },

    showHideButton() {
      if (this.$parent.booking.bookingTypeName == "FCE Lite" || this.$parent.booking.bookingTypeName == "FCE Lite by Video") {
        this.hideButtons = true
      } else {
        this.hideButtons = false
      }
    },

    async sendEmail() {
      this.hasError = false;
      if (!this.validateSubject || !this.validateMessage || !this.validateEmail || !this.validateEmailTo || !this.validatePhone) {
        this.hasError = true;
        return
      } else {
        this.sendToProps = this.sendTo
        this.$store.dispatch("loading/setLoadComponent", true);
        if (this.emailOrSms === 0) {
          let params = {
            Content: this.email.Content,
            Subject: this.email.Subject,
            Method: "email",
            EmailCc: this.email.EmailCc ? this.email.EmailCc.join(',') : '',
            EmailBcc: this.email.EmailBcc ? this.email.EmailBcc.join(',') : '',
            BookingID: this.$parent.booking.id,
            UserID: this.$parent.user.id,
            Username: this.$parent.user.name,
            CurrentDate: window.moment().format('YYYY-MM-DD HH:mm:ss'),
          };
          if(this.sendTo === 'rhadmin') {
            params.SendToRH2 = 1
          } else {
            params.EmailTo = this.email.EmailTo
          }
          let attach = []
          let standards = []
          this.files.forEach(item => {
            if(item.type === 'link') {
              standards.push(item.name)
            } else {
              attach.push(item.name)
            }
          });
          if(attach.length > 0) params.Attachments = attach
          if(standards.length > 0) params.StandardAttachments = standards
          const res = await utilities.sendEmailFromBooking(params);
          if (res) {
            this.$parent.successMessage =
              "Email sent to " + this.sendToProps + ".";
            utilities.showAlert(".alert-success");
            this.handleModal();
            this.$emit('sendSuccess')
          }
        } else {
          let phone = this.phone
          if(phone.charAt(0) == '0' && phone.charAt(1) == '4') phone = "+61"+phone.substring(1)
          var params = {
            PhoneNumber: phone,
            Content: this.email.smsBody,
            Method: "sms",
            BookingID: this.$parent.booking.id,
            UserID: this.$parent.user.id,
            Username: this.$parent.user.name,
            CurrentDate: window.moment().format('YYYY-MM-DD HH:mm:ss'),
          };
          params.Attachments = this.files.map(item => {
            if(item.type === 'link') {
              return this.getUrl(item)
            } else {
              return item.name
            }
          });
          var res = await utilities.sendEmailFromBooking(params);
          if (res) {
            this.$parent.successMessage =
              "SMS sent to " + this.sendToProps + ".";
            utilities.showAlert(".alert-success");
            this.handleModal();
            this.$emit('sendSuccess')
          }
        }
        this.$store.dispatch("loading/setLoadComponent", false);
      }
    },

    getUrl(file) {
      if(file.type === 'link') {
        return utilities.getConsentFormUrl(file.name);
      } else {
        return file.name
      }
    },
  },
  beforeMount() {
    this.sendTo = this.sendToProps
    if (this.$parent.booking.bookingTypeName == "FCE Lite" || this.$parent.booking.bookingTypeName == "FCE Lite by Video") {
      this.hideButtons = true
    } else {
      this.hideButtons = false
    }
    utilities.getEmailTemplate({PageNum: 1, PageSize: 999}).then(res => {
      this.records = res.EmailTemplates ? res.EmailTemplates : []
      this.records = this.records.map(item => {
        return {
          ...item,
          Subject: decodeURIComponent(item.Subject),
          TemplateName: decodeURIComponent(item.TemplateName),
          EmailCc: item.EmailCc ? [decodeURIComponent(item.EmailCc)] : [],
          EmailBcc: item.EmailBcc ? [decodeURIComponent(item.EmailBcc)] : [],
          Body: decodeURIComponent(item.Body),
        }
      })
      this.filteredRecords = this.records
    })
  },
  mounted() {},
  async updated() {
    // if (this.loaded == false) {
    //   this.standardFiles = await utilities.getStandardForms();
    //   this.emailOrSms = this.emailSmsProps;
    //   this.loaded = true;
    //   this.standardFiles.sort((a, b) => {
    //     return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    //   });
    // }
  },
};
</script>
